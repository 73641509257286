@import '_variables';
@import '_mixins';
@import '_fonts';


.slider {
	max-height: 65rem;
    article {
        max-height: 65rem;
        
        .photo {
            max-height: 65rem;
        }
    }
}